import { FC, useLayoutEffect, useRef, useState } from "react";
import { ZTooltip } from "../../ZTooltip/ZTooltip";

interface ZFileNameProps {
  filename: string;
}

const ZFileName: FC<ZFileNameProps> = ({ filename }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useLayoutEffect(() => {
    if (textRef.current) {
      const { clientHeight, scrollHeight } = textRef.current;
      textRef.current.innerHTML = textRef.current.innerHTML.trim().replace(/\s+\.\.\.$/, "...");
      setIsTruncated(scrollHeight > clientHeight);
    }
  }, [filename, textRef.current]);

  const fileNameParts = filename.split(".");
  const fileExtension = fileNameParts.length > 1 ? fileNameParts.pop() : "";
  const fileNameWithoutExtension = fileNameParts.join(".");

  return (
    <ZTooltip showArrow content={filename} isDisabled={!isTruncated}
    >
      <div className="flex">

        <div ref={textRef} className="truncate-one-line break-word-only">
          {fileNameWithoutExtension}
        </div>
        {fileExtension && <span>.{fileExtension}</span>}
      </div>
    </ZTooltip>
  );
};

export default ZFileName;
