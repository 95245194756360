import FileUpload from "@/shared/components/custom/file-upload";
import { UploadedFile } from "@/shared/types/file-upload";
import { cn } from "@/shared/utils/classname-merger";
import {
  Avatar,
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  Select,
  SelectItem,
  Switch,
  Textarea,
} from "@nextui-org/react";
import React, { useState, useMemo, useEffect } from "react";

import { AgentSessionStepType } from "@/modules/sessions/types";
import { AgentSessionStatus } from "@/modules/sessions/types";
import AddCertificateModal from "@/shared/components/add-certification-modal/AddCertificateModal";
import ZAvatarGradient from "@/shared/components/avatarGradient";
import { useParams } from "react-router-dom";
import {
  NATURE_OF_RELATIONSHIP,
  VENDOR_BUSINESS_CRITICALITY,
  VENDOR_DATA_ACCESS_SCOPE,
  VENDOR_DATA_STORAGE,
  VENDOR_DATA_TYPES_HANDLED,
  VENDOR_INCIDENTS_IN_LAST_36_MONTHS,
  VENDOR_PREDEFINED_COMPLIANCE_CERTS,
  VENDOR_SYSTEM_ACCESS,
} from "../../constants/vendorAssessmentConstants";
import { useAgentStepData } from "../../states";
import { IdParams } from "../VendorRiskAssessment/Overview/Overview";
import { doesFileHasError } from "@/shared/components/custom/file-upload/helper";

interface OverviewSectionProps {
  formData: {
    vendor_usage_description: string;
    vendor_ai_ml_usage: string;
    compliance_certifications: string[];
    customer_added_certifications: string[];
    nature_of_relationship: string;
    specify_relationship_type_other: string;
  };
  handleVendorUsageDescriptionChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleSwitchChange: (e: string) => void;
  handleCheckboxChange: (selectedItems: string[]) => void;
  handleAddCustomCertification: (certificateName: string) => void;
  handleOtherValueChange: (
    field: string,
    value: string,
  ) => void;
  handleSelectChange: (
    field: string,
    selectedItems: string[]
  ) => void;
}

export const OverviewSection: React.FC<OverviewSectionProps> = ({
  formData,
  handleVendorUsageDescriptionChange,
  handleSwitchChange,
  handleCheckboxChange,
  handleAddCustomCertification,
  handleSelectChange,
  handleOtherValueChange
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams<keyof IdParams>() as IdParams;
  const stepData = useAgentStepData(id);
  const isReportReady =
    stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)
      ?.status === AgentSessionStatus.COMPLETE;

  // Create a unique key for each certification
  const certifications = useMemo(() => {
    const predefinedCertifications = VENDOR_PREDEFINED_COMPLIANCE_CERTS.map(
      (cert) => ({
        ...cert,
        key: `predefined-${cert.label}`,
      }),
    );

    const customCertifications = formData.customer_added_certifications.map(
      (cert, index) => ({
        label: cert,
        logo: "",
        isCustom: true,
        key: `custom-${index}-${cert}`,
      }),
    );

    return [...predefinedCertifications, ...customCertifications];
  }, [formData.customer_added_certifications]);

  const shouldScroll = certifications.length > 20;
  return (
    <div className="flex flex-col gap-6">
      <span className="font-medium text-sm">Overview</span>
      <div className="flex flex-col gap-4">
        <div className="flex gap-8">
          <div className="flex flex-col gap-2 flex-1">
            <span className="text-sm text-[#71717A]">
             Clearly describe what this third party will be used for <span className="text-red-500">*</span>
            </span>
            <Textarea
              className="w-full"
              classNames={{
                inputWrapper:
                  "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
              }}
              placeholder="Add description"
              value={formData.vendor_usage_description}
              onChange={(e) =>
                handleVendorUsageDescriptionChange(
                  e as unknown as React.ChangeEvent<HTMLTextAreaElement>,
                )
              }
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <span className="text-sm text-[#71717A]">
              Does this product incorporate any AI / ML components?
            </span>
            <Switch
              key={formData.vendor_ai_ml_usage}
              isSelected={formData.vendor_ai_ml_usage === "Yes"}
              onChange={(e) =>
                handleSwitchChange(e.target.checked ? "Yes" : "No")
              }
            >
              Yes
            </Switch>
          </div>
        </div>
        <div className="flex gap-8 w-full" >
          <div className="flex flex-col w-1/2 gap-4">
            <Select
              classNames={{
                mainWrapper: cn(
                  "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
                ),
                popoverContent: cn(
                  "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
                ),
                trigger: cn("rounded-md bg-white"),
                label: cn("!text-sm !text-[#71717A]"),
              }}
              items={NATURE_OF_RELATIONSHIP}
              label={`Nature of Relationship`}
              labelPlacement="outside"
              placeholder="Select all applicable options"
              selectedKeys={[formData.nature_of_relationship]}
              onSelectionChange={(selectedItems) => {
                handleSelectChange(
                  "nature_of_relationship",
                  Array.from(selectedItems).map(String),
                );
                const item = Array.from(selectedItems)[0];
                if (item !== 'Other') {
                  handleOtherValueChange("specify_relationship_type_other", "")
                }
              }

              }
            >
              {(item) => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              )}
            </Select>
            {formData.nature_of_relationship === "Other" && (
              <Input
                placeholder="eg: Consultant"
                label="Specify Relationship Type (Other)"
                labelPlacement="outside"
                value={formData.specify_relationship_type_other}
                onChange={(e) =>
                  handleOtherValueChange(
                    "specify_relationship_type_other",
                    e.target.value,
                  )
                }
                classNames={{
                  inputWrapper:
                    "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
                  label: cn("!text-sm !text-[#71717A]"),
                }}
              />
            )}
          </div>
          <div className="w-1/2"></div>
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-sm text-[#71717A]">Compliance Adherence</span>
          <div
            className={`mt-2 ${shouldScroll ? "max-h-[400px] overflow-y-auto" : ""}`}
          >
            <CheckboxGroup
              orientation="horizontal"
              value={formData.compliance_certifications}
              onChange={handleCheckboxChange}
              classNames={{
                wrapper: cn("grid grid-cols-4 gap-4 gap-y-8"),
              }}
            >
              {certifications.map((item) => (
                <Checkbox
                  key={item.key}
                  value={item.label}
                  classNames={{
                    base: cn(
                      "hover:bg-content2 items-center justify-start",
                      "cursor-pointer rounded-md gap-2 p-2",
                      "w-full",
                    ),
                    label: "w-full",
                  }}
                >
                  <div className="w-full flex items-center justify-between gap-2">
                    {item.logo ? (
                      <Avatar
                        size="sm"
                        name={item.label}
                        src={item.logo}
                        className="max-w-6 max-h-6 text-[8px] "
                      />
                    ) : (
                      <ZAvatarGradient
                        name={item.label}
                        size={24}
                        textSize={10}
                        backgroundColor="#E4E4E7"
                        shadow="shadow-none"
                        textColor="text-[#171717]"
                      />
                    )}
                    <span className="text-sm">{item.label}</span>
                  </div>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>
        </div>
        <Button
          variant="bordered"
          color="primary"
          className="rounded-md w-fit text-xs leading-5 px-3 py-1 h-7"
          onClick={() => setIsModalOpen(true)}
        >
          Add Certification
        </Button>
        <AddCertificateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(certificateName) => {
            handleAddCustomCertification(certificateName);
            setIsModalOpen(false);
          }}
        />
      </div>
    </div>
  );
};

interface DataHandlingSectionProps {
  formData: {
    collected_data_types: {
      data_types: string[];
      other_data_types: string;
    };
    data_access_scope: {
      scope: string;
      other_scope: string;
    };
    data_storage: string[];
    compliance_certifications: string[];
  };
  handleSelectChange: (field: string, selectedItems: any) => void;
  handleOtherValueChange: (field: string, value: string) => void;
}

export const DataHandlingSection: React.FC<DataHandlingSectionProps> = ({
  formData,
  handleSelectChange,
  handleOtherValueChange,
}) => (
  <div className="flex flex-col gap-6">
    <span className="font-medium text-sm">Data Handling</span>
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col gap-4 w-full">
          <Select
            classNames={{
              mainWrapper: cn(
                "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
              ),
              popoverContent: cn(
                "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
              ),
              trigger: cn("rounded-md bg-white"),
              label: cn("!text-sm !text-[#71717A]"),
            }}
            items={VENDOR_DATA_TYPES_HANDLED}
            label={<>Data Types Collected <span className="text-red-500">*</span></>}
            labelPlacement="outside"
            placeholder="Select all applicable options"
            selectionMode="multiple"
            selectedKeys={formData.collected_data_types.data_types}
            onSelectionChange={(selectedItems) =>
              handleSelectChange(
                "collected_data_types.data_types",
                selectedItems,
              )
            }
          >
            {(item) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            )}
          </Select>
          {formData.collected_data_types?.data_types?.includes("Other") && (
            <Textarea
              placeholder="Add data type"
              value={formData.collected_data_types.other_data_types}
              onChange={(e) =>
                handleOtherValueChange(
                  "collected_data_types.other_data_types",
                  e.target.value,
                )
              }
              classNames={{
                inputWrapper:
                  "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
              }}
            />
          )}
        </div>
        <div className="flex flex-col gap-4 w-full">
          <Select
            classNames={{
              mainWrapper: cn(
                "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
              ),
              popoverContent: cn(
                "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
              ),
              trigger: cn("rounded-md bg-white"),
              label: cn("!text-sm !text-[#71717A]"),
            }}
            items={VENDOR_DATA_ACCESS_SCOPE}
            label={<>Data Access Scope <span className="text-red-500">*</span></>}
            labelPlacement="outside"
            placeholder="Select an option"
            selectionMode="single"
            selectedKeys={[formData.data_access_scope.scope]}
            onSelectionChange={(selectedItems) =>
              handleSelectChange(
                "data_access_scope.scope",
                Array.from(selectedItems).map(String),
              )
            }
          >
            {(item) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            )}
          </Select>
          {formData.data_access_scope?.scope === "Other" && (
            <Textarea
              placeholder="Add data access scope"
              value={formData.data_access_scope.other_scope}
              onChange={(e) =>
                handleOtherValueChange(
                  "data_access_scope.other_scope",
                  e.target.value,
                )
              }
              classNames={{
                base: cn("w-full"),
                inputWrapper:
                  "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
              }}
            />
          )}
        </div>
        <div className="flex gap-4 w-full">
          <Select
            classNames={{
              mainWrapper: cn(
                "w-[calc(50%-8px)] shadow-none rounded-md border-1 border-gray-300 bg-white w-full",
              ),
              popoverContent: cn(
                "shadow-none rounded-md border-1 border-gray-300 bg-white w-full",
              ),
              trigger: cn("rounded-md bg-white"),
              label: cn("!text-sm !text-[#71717A]"),
            }}
            className="w-full"
            items={VENDOR_DATA_STORAGE}
            fullWidth
            label={<>Data Storage <span className="text-red-500">*</span></>}
            labelPlacement="outside"
            placeholder="Select all applicable options"
            selectionMode="multiple"
            selectedKeys={formData.data_storage}
            onSelectionChange={(selectedItems) =>
              handleSelectChange("data_storage", selectedItems)
            }
          >
            {(item) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            )}
          </Select>
        </div>
      </div>
    </div>
  </div>
);

interface RiskExposureSectionProps {
  formData: {
    business_criticality: string;
    incidents_in_last_36_months: string;
    system_access_level: string;
  };
  handleSelectChange: (field: string, selectedItems: string | string[]) => void;
}

export const RiskExposureSection: React.FC<RiskExposureSectionProps> = ({
  formData,
  handleSelectChange,
}) => (
  <div className="flex flex-col gap-6">
    <span className="font-medium text-sm">Risk and Exposure</span>
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-2 gap-8">
        <Select
          classNames={{
            mainWrapper: cn(
              "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
            ),
            popoverContent: cn(
              "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
            ),
            trigger: cn("rounded-md bg-white"),
            label: cn("!text-sm !text-[#71717A]"),
          }}
          items={VENDOR_BUSINESS_CRITICALITY}
          label={<>Estimated Business Criticality <span className="text-red-500">*</span></>}
          labelPlacement="outside"
          placeholder="Select an option"
          selectedKeys={[formData.business_criticality]}
          onSelectionChange={(selectedItems) =>
            handleSelectChange(
              "business_criticality",
              Array.from(selectedItems).map(String),
            )
          }
        >
          {(item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          )}
        </Select>
        <Select
          classNames={{
            mainWrapper: cn(
              "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
            ),
            popoverContent: cn(
              "w-full shadow-none rounded-md border-1 border-gray-300 bg-white",
            ),
            trigger: cn("rounded-md bg-white"),
            label: cn("!text-sm !text-[#71717A]"),
          }}
          items={VENDOR_INCIDENTS_IN_LAST_36_MONTHS}
          label={<>Incidents in the last 36 months <span className="text-red-500">*</span></>}
          labelPlacement="outside"
          placeholder="Select an option"
          selectedKeys={[formData.incidents_in_last_36_months]}
          onSelectionChange={(selectedItems) =>
            handleSelectChange(
              "incidents_in_last_36_months",
              Array.from(selectedItems).map(String),
            )
          }
        >
          {(item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          )}
        </Select>
        <Select
          classNames={{
            mainWrapper: cn(
              "w-[calc(50%-8px)] shadow-none rounded-md border-1 border-gray-300 bg-white w-full",
            ),
            popoverContent: cn(
              "shadow-none rounded-md border-1 border-gray-300 bg-white",
            ),
            trigger: cn("rounded-md bg-white"),
            label: cn("!text-sm !text-[#71717A]"),
          }}
          items={VENDOR_SYSTEM_ACCESS}
          label={<>System Access Level <span className="text-red-500">*</span></>}
          labelPlacement="outside"
          placeholder="Select an option"
          fullWidth
          className="w-full"
          selectedKeys={[formData.system_access_level]}
          onSelectionChange={(selectedItems) =>
            handleSelectChange(
              "system_access_level",
              Array.from(selectedItems).map(String),
            )
          }
        >
          {(item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          )}
        </Select>
      </div>
    </div>
  </div>
);

//

export const DocumentsSection: React.FC<{
  updateSignedUrl: (files: UploadedFile[]) => void;
  initialFiles: UploadedFile[];
  setIsThereFileUploadError: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ updateSignedUrl, initialFiles, setIsThereFileUploadError }) => {
  const [files, setFiles] = useState<UploadedFile[]>(initialFiles);

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  const handleFileChange = (newFiles: UploadedFile[]) => {
    const isThereErrorInFilesUploaded =
      newFiles.filter((file) => doesFileHasError(file)).length > 0;

    setIsThereFileUploadError(isThereErrorInFilesUploaded);

    setFiles(newFiles);
    updateSignedUrl(newFiles);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-8">
        <span className="font-medium text-sm">Documents <span className="text-red-500">*</span></span>
        <span className="text-sm text-[#71717A]">
          Include SOC 2 reports, penetration tests, questionnaires, policies and other
          relevant trust center materials.  
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <FileUpload
          maxFiles={30}
          orientation="horizontal"
          isMultiple={true}
          allowedFileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "application/json",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/png",
            "image/jpeg",
            "image/jpg",
            "text/csv",
          ]}
          setFiles={handleFileChange}
        />
      </div>
    </div>
  );
};
